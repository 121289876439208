import React, { Component } from "react";

import {
  Table,
  Button,
  Input,
  Icon,
  notification,
  Tag
} from "antd";

import axios from "axios";

class RoundReport extends Component {
  state = {
    dataSource: [],
    fetching: true,
  };

  componentDidMount = async () => {
    const { data: dataSource } = await axios.get("auth/get_users/")
    this.setState({ fetching: false, dataSource })
  };

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };

  render() {
    return (
      <div>
        <p style={{fontSize: 20}}>Latest Round Report</p>
      </div>
    )
  }
}

export default RoundReport