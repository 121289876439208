import React, { Component } from "react";

import {
  Table,
  Button,
  Divider,
  Popconfirm,
  Input,
  Icon,
  notification,
} from "antd";
import Highlighter from "react-highlight-words";
import axios from "axios";

import MessageDetails from "./MessageDetails"

class MessageView extends Component {
  state = {
    dataSource: [],
    onVisibleCreateView: false,
    fetching: true,
    visibleMessageDetails: false,
    selectedMessage: undefined,
  };

  componentDidMount = async () => {
    const { data: dataSource } = await axios.get("message/get_all_messages/");
    this.setState({ dataSource, fetching: false })
  };

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };

  deleteMessage = async (id) =>  {
    let data = {
      id: id
    }
    const { data : deleteData } = await axios.delete('/message/messages/', {data: data})
    const dataSource = this.state.dataSource.filter(
      record => record.id !== id
    );
    this.setState({dataSource})
  }

  searchEntry(dataSource, searchText) {
    if (!searchText) {
      return dataSource
    } else {
      let filtered = dataSource.filter(
        it =>
          (it.receiver && it.receiver.toLowerCase().includes(searchText))
      );
      return filtered
    }
  }

  onCancel = () => {
    this.setState({visibleMessageDetails: false, selectedMessage: undefined})
  }

  TableView = () => {
    const { dataSource, fetching, searchText } = this.state;

    const columns = [
      { 
        title: "Receiver", 
        key: "receiver",
        dataIndex: "receiver",
        render: (text, record) => (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        )
      },
      {
        title: "Date Sent",
        dataIndex: "date_sent",
        key: "date_sent",
        render: (text, record) => (
          <p>
            {record.created_at}
          </p>
        )
      },
      {
        title: "Subject",
        dataIndex: "subject",
        key: "subject",
        render: (text, record) => (
          <p>
            {record.subject}
          </p>
        )
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        render: (text, record) => {
          return (
            <>
              <Button type="link"
                onClick={() => {this.setState({ selectedMessage: record, visibleMessageDetails: true })}}
              >View</Button>
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure you want to delete this message?"
                onConfirm={() => {this.deleteMessage(record.id)}}
              >
                <Button type="link">
                  Delete
                </Button>
              </Popconfirm>
            </>
          )
        }
      }
    ];

    return (
      <>
        <div style={{ width: "100%" }}>
          <span style={{ fontSize: 20, marginLeft: "20px", marginBottom: "20px" }}>
            Messages
          </span>

          <div
            style={{
              marginTop: "20px",
              marginBottom: "70px",
              width: "100%"
            }}
          >
            <Input
              style={{ float: "left", width: "30%" }}
              placeholder="Search by Email"
              onChange={e => {
                this.setState({ searchText: e.target.value.toLowerCase() });
              }}
              prefix={
                <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
              }
            />
          </div>
          <Table
            rowKey={record => record.id}
            bordered
            loading={fetching}
            columns={columns}
            dataSource={this.searchEntry(dataSource, searchText)}
          />
        </div>
      </>
    );
  };
  render() {
    return (
      <div>
        {this.TableView()}
        <MessageDetails 
          visible={this.state.visibleMessageDetails} 
          message={this.state.selectedMessage} 
          onCancel={() => {this.onCancel()}}  
        />
      </div>
    )
  }
}

export default MessageView
