import React, { Component, Suspense, lazy } from "react";
import { Route, NavLink, Switch, Redirect } from "react-router-dom";
import { Layout, Menu, Button, Divider } from "antd";
import preval from "preval.macro";

import AppContext from "../AppContext";
import MessageView from "./admin/MessageView";
import UserView from './admin/UserView.js'
import RoundReport from "./admin/RoundReport.js"
const Application = lazy(() => import("./admin/application/Application"));
const ApplicationResponse = lazy(() => import("./admin/ApplicationResponse"));
const GrantYear = lazy(() => import("./admin/grantyear/GrantYear"));

const ApplicationReview = lazy(() => import("./user/ApplicationReview"));
const MyApplication = lazy(() => import("./user/MyApplication"));

class Dashboard extends Component {
  static contextType = AppContext;
  state = {
    fullScreenMode: false
  };

  switchRoles = () => {
    let status = sessionStorage.getItem('isAdmin')
    if (status === "true") {
      sessionStorage.setItem('isAdmin', "false")
    } else {
      sessionStorage.setItem('isAdmin', "true")
    }
    window.location.reload()
  }

  render() {
    const { location } = this.props;

    const { logout, isAdmin } = this.context;

    const currentKey = location.pathname.split("/")[1].toLowerCase();

    let isSuper = sessionStorage.getItem('isSuper')

    let userDetails = {}
    userDetails.first_name = sessionStorage.getItem('first_name')
    if (sessionStorage.getItem('isAdmin') === "true") {
      userDetails.role = "Administrator"
    } else {
      userDetails.role = "User"
    }
    if (sessionStorage.getItem('isSuper')) {
      userDetails.role = "Superuser ("+userDetails.role+")"
    }
    return (
      <Layout style={{ height: "100%" }}>
        <Layout.Sider>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              background: "#120212"
            }}
          >
            <img
              className="logo"
              src="https://cdn.teaching.unsw.edu.au/unswbranding/unsw_neg.png"
              alt="UNSW Logo"
              style={{ width: "80%", margin: 20 }}
            />

            <div style={{ flex: 1 }}>
              <Menu
                theme="dark"
                mode="inline"
                defaultOpenKeys={["manage", "application"]}
                selectedKeys={[currentKey]}
              >
                <Menu.SubMenu title="My Actions" key="application">
                  <Menu.Item key="myapplication">
                    <NavLink to="/myapplication">New Applications</NavLink>
                  </Menu.Item>
                  <Menu.Item key="review">
                    <NavLink to="/review">Reviews</NavLink>
                  </Menu.Item>
                </Menu.SubMenu>

                {isAdmin && (
                  <Menu.SubMenu title="Admin" key="manage">
                    <Menu.Item key="surveyresponse">
                      <NavLink to="/surveyresponse">List Submissions</NavLink>
                    </Menu.Item>
                    <Menu.Item key="message">
                      <NavLink to="/message">Messages</NavLink>
                    </Menu.Item>
                    <Menu.Item key="roundreport">
                        <NavLink to="/roundreport">Round Report</NavLink>
                      </Menu.Item>
                    <Menu.SubMenu title="Settings" key="settings">
                      <Menu.Item key="grantyear">
                        <NavLink to="/grantyear">Grant Year</NavLink>
                      </Menu.Item>
                      <Menu.Item key="application">
                        <NavLink to="/application">Grant Rounds</NavLink>
                      </Menu.Item>
                      <Menu.Item key="user">
                        <NavLink to="/user">All Users</NavLink>
                      </Menu.Item>
                    </Menu.SubMenu>
                  </Menu.SubMenu>
                )}
              </Menu>
            </div>

            <div
              style={{
                textAlign: "center",
                color: "rgba(117, 117, 117, 0.5)",
                margin: "20px 0"
              }}
            >
              <div>
                <p style={{color: "green"}}>•</p>
                <p style={{color: "white", display: 'block'}}>{userDetails.first_name}</p>
                <p style={{color: "white"}}>{userDetails.role}</p>
              </div>
              {`Build date: ${preval`
                const moment = require("moment");
                module.exports = moment().format("DD/MM/YYYY");
              `}`}
            </div>
          </div>
        </Layout.Sider>

        <Layout>
          <Layout.Header
            style={{ background: "#fff", padding: "0 2px", margin: 10 }}
          >
            <h1 style={{ marginLeft: 20, textAlign: "center" }}>
              Education-Focussed Career Development Fund Application System
              <Button
                type="warning"
                icon="poweroff"
                onClick={logout}
                style={{
                  float: "right",
                  marginTop: "15px",
                  marginRight: "1%"
                }}
              >
                Logout
              </Button>
              {isSuper && (
                <Button
                type="primary"
                onClick={() => this.switchRoles()}
                style={{
                  float: "right",
                  marginTop: "15px",
                  marginRight: "1%"
                }}
                >
                Switch Roles
                </Button>
              )}
             
              <p style={{float: "right", marginRight: "1%"}}>{userDetails.first_name} - {userDetails.role}</p>
              <div
                style={{
                  float: "right",
                  marginTop: "15px",
                  marginRight: "2%"
                }}
              />
            </h1>
          </Layout.Header>

          <Layout.Content
            style={{
              margin: "1px 16px 0",
              display: "flex",
              flexDirection: "column",
              height: "100%"
            }}
          >
            <div style={{ padding: 24, background: "#fff", flex: "1" }}>
              <Switch>
                { isAdmin ? <Redirect exact from="/" to="/surveyresponse" /> :
                  <Redirect exact from="/" to="/myapplication" />
                }

                <Suspense fallback={<div>Loading...</div>}>
                  {isAdmin && (
                    <>
                      <Route path="/grantyear" component={GrantYear} />
                      <Route path="/application" component={Application} />
                      <Route
                        path="/surveyresponse"
                        component={ApplicationResponse}
                      />
                      <Route path="/message" component={MessageView} />
                      <Route path="/user" component={UserView} />
                      <Route path="/roundreport" component={RoundReport} />
                    </>
                  )}
                  <Route path="/myapplication" component={MyApplication} />
                  <Route path="/review" component={ApplicationReview} />
                </Suspense>
              </Switch>
            </div>
          </Layout.Content>
        </Layout>
      </Layout>
    );
  }
}

export default Dashboard;
