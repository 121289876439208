import React, { Component } from "react";

import {
  Table,
  Button,
  Input,
  Icon,
  notification,
  Tag,
  Menu,
  Dropdown,
  Radio
} from "antd";
import Highlighter from "react-highlight-words";

import { DownOutlined } from '@ant-design/icons';

import axios from "axios";

class UserView extends Component {
  state = {
    dataSource: [],
    fetching: true,
    filter: "no_filter",
    searchText: "",
  };

  componentDidMount = async () => {
    const { data: dataSource } = await axios.get("auth/get_users/")
    this.setState({ fetching: false, dataSource })
  };

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description
    });
  };

  searchEntry(dataSource, searchText) {
    if (!searchText && this.state.filter === "no_filter") {
      return dataSource
    } else if (!searchText && this.state.filter !== "no_filter") {
      return dataSource.filter(it => it.role === this.state.filter)
    } else {
      let filtered = dataSource.filter(
        it =>
          (it.zid && it.zid.toLowerCase().includes(searchText))
          || (it.email && it.email.toLowerCase().includes(searchText))
          || (it.display_name && it.display_name.toLowerCase().includes(searchText))
      );
      if (this.state.filter !== "no_filter") {
        return filtered.filter(it => it.role === this.state.filter)
      } else {
        return filtered
      }
    }
  }

  filterRadio = (e) => {
    this.setState({ filter: e.target.value })
  }

  handleOnChangeRole = async (user, role) => {
    this.setState({ fetching: true });
    try {
      const { data } = await axios.put("auth/edit_role/", {id: user.zid, role: role})

      const index = this.state.dataSource.findIndex(
        record => record.id === data.id
      );
      if (index >= 0) {
        this.openNotificationWithIcon("success", "Record updated successfully")
      }
      const dataSource =
        index < 0
          ? [...this.state.dataSource, data]
          : [
              ...this.state.dataSource.slice(0, index),
              data,
              ...this.state.dataSource.slice(index + 1)
            ];
      this.setState({
        dataSource,
        onVisibleCreateView: false,
        fetching: false
      });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
      this.setState({ fetching: false });
    }
  };

  handleOnDeleteStudent = async id => {
    this.setState({ fetching: true });
    try {
      await axios.delete("application/", { data: { id } });
      const dataSource = this.state.dataSource.filter(
        record => record.id !== id
      );

      this.setState({ dataSource, fetching: false });
    } catch (error) {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.response.data.message
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.request
        );
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log(
          "Error",
          "An error occurred, please try again later",
          error.message
        );
        this.openNotificationWithIcon(
          "error",
          "An error occurred, please try again later",
          error.message
        );
      }
      console.log(error);
      this.setState({ fetching: false });
    }
  };

  changeRole = () => {

  }

  TableView = () => {
    const { dataSource, fetching, searchText } = this.state;

    const columns = [
      { 
        title: "zID", 
        key: "zid",
        dataIndex: "zid", 
        render: (text, record) => (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        )
      },
      {
        title: "Name",
        dataIndex: "display_name",
        key: "display_name",
        render: (text, record) => (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        )
      },
      {
        title: "E-mail",
        dataIndex: "email",
        key: "email",
        render: (text, record) => (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        )
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        render: (text, record) => {
          if (record.role === "EF_TEAM") {
            return <Tag color="purple">EF TEAM</Tag>
          } else if (record.role === "ADMIN") {
            return <Tag color="blue">ADMIN</Tag>
          } else if (record.role === "HOS") {
            return <Tag color="green">HOS</Tag>
          } else { // user
            return <Tag color="orange">USER</Tag>
          }
        }
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        render: (text, record) => {
          const menu = (
            <Menu>
              <Menu.Item>
                <Button type="link"
                  onClick={() => {this.handleOnChangeRole(record, "EF_TEAM")}}
                >
                  EF Team
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button type="link"
                  onClick={() => {this.handleOnChangeRole(record, "HOS")}}
                >
                  Head of School
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button type="link"
                  onClick={() => {this.handleOnChangeRole(record, "USER")}}
                >
                  User
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button type="link"
                  onClick={() => {this.handleOnChangeRole(record, "ADMIN")}}
                >
                  Administrator
                </Button>
              </Menu.Item>
            </Menu>
          )
          return (
            <Dropdown overlay={menu}>
              <Button type="link">Edit Role<DownOutlined /></Button>
            </Dropdown>
          )
        }
      }
    ];

    return (
      <>
        <div style={{ width: "100%" }}>
          <span style={{fontSize: 20, marginLeft: "20px", marginBottom: "20px" }}>
            Users
          </span>
          <div
            style={{
              marginTop: "20px",
              marginBottom: "70px",
              width: "100%"
            }}
          >
            <Input
              style={{ float: "left", width: "30%" }}
              placeholder="Search by Title, Code"
              onChange={e => {
                this.setState({ searchText: e.target.value.toLowerCase() });
              }}
              prefix={
                <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
              }
            />
          </div>
          <div style={{width: "70%", display: "flex", flex: 1, paddingBottom: 20, flexDirection: "column"}}>
            <p style={{paddingRight: 30}}>Filter by Role</p>
            <Radio.Group onChange={this.filterRadio} defaultValue="no_filter">
              <Radio.Button value="no_filter">No filter</Radio.Button>
              <Radio.Button value="EF_TEAM">EF Team</Radio.Button>
              <Radio.Button value="HOS">HOS</Radio.Button>
              <Radio.Button value="USER">User</Radio.Button>
              <Radio.Button value="ADMIN">Administrator</Radio.Button>
            </Radio.Group>
          </div>
          <Table
            rowKey={record => record.id}
            bordered
            loading={fetching}
            columns={columns}
            dataSource={this.searchEntry(dataSource, searchText)}
            pagination={{ position: 'both', defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
          />
        </div>
      </>
    );
  };
  render() {
    return (
      <div>
        {this.TableView()}
      </div>
    )
  }
}

export default UserView
